// import Blazy from "blazy"
export default class Page {
    constructor() {
        this.sliders = []
        this.swiper()
        // this.blazy()
    }

    swiper() {  
        this.sliders = []
        document.querySelectorAll(".swiper").forEach((item) => {
            const slide = new Swiper(item, {
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                },                
                // Navigation arrows
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            })
            this.sliders.push(slide)
        })
    }

    blazy() {
        return new Blazy({
            success: (el) => {
                el.parentElement.classList.add("loaded")
            }
        })
    }
}